<template>
<div>
  <!-- 面包屑导航区 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>订单管理</el-breadcrumb-item>
    <el-breadcrumb-item>商城订单列表</el-breadcrumb-item>
  </el-breadcrumb>
  <!-- 卡片视图 -->
  <el-card>
    <!-- <el-row>
        <el-col :span="6">
          <el-input placeholder="请输入内容">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
      </el-row> -->

    <!-- 订单列表 -->
    <el-table :data="list" id="mainTable" border :height="tableHeight">

      <el-table-column width="125px" label="商品图片">
        <template slot-scope="scope"><img :src="scope.row.image" width="100px" height="100px" /></template>
      </el-table-column>
      <el-table-column label="商品名称" width="150px" prop="goodsName"></el-table-column>
      <el-table-column label="商品规格" width="150px">
        <template slot-scope="scope">
          <el-tag type="success" size="mini">{{ scope.row.spec }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="120px" label="当前状态">
        <template slot-scope="scope">
          <el-tag type="default" size="mini">{{
            getStatus(scope.row.status)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="预定数量(件)" prop="number" width="100px">
        <template slot-scope="scope">
          <el-tag type="info" size="mini">{{ scope.row.number }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="已配送数量(件)" prop="deliveredNumber" width="120px">
        <template slot-scope="scope">
          <el-tag style="cursor: pointer" type="success" size="mini" @click="viewDeliverList(scope.row)">{{
            scope.row.deliveredNumber || 0 }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单编号" width="200px" prop="shop_orderNo"></el-table-column>
      <el-table-column label="下单时间" width="150px">
        <template slot-scope="scope">{{
          scope.row.createtime | dataFormat
        }}</template>
      </el-table-column>
      <el-table-column width="200px" label="期望送达时间">
        <template slot-scope="scope">
          <el-tag type="success">{{ scope.row.startDate | dataFormat }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="200px" label="下单人">
        <template slot-scope="scope">
          <el-tag type="success">{{
            `${scope.row.name}(${scope.row.mobile})`
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="配送地址" width="400px">
        <template slot-scope="scope">
          {{ scope.row.address }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="500px">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 1" type="primary" size="mini" icon="el-icon-confirm"
            @click="updateStatus(scope.row, 2)">开始配送</el-button>
          <el-button v-if="scope.row.status == 2" type="success" size="mini" icon="el-icon-confirm"
            @click="updateDeliverProgress(scope.row)">更新配送进度</el-button>
          <el-button v-if="scope.row.status == 2 && scope.row.deliveredNumber > 0" type="info" size="mini"
            icon="el-icon-confirm" @click="updateStatus(scope.row, 3)">完成配送</el-button>
          <el-button v-if="scope.row.deliveredNumber" type="default" size="mini" icon="el-icon-confirm"
            @click="viewDeliverList(scope.row)">查看配送凭证</el-button>
          <el-button type="default" size="mini" icon="el-icon-location"
            @click="viewLocation(scope.row)">查看位置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total"></el-pagination>
  </el-card>

  <el-dialog title="更新配送进度" :visible.sync="updateProgressDialogVisible" width="40%" :close-on-press-escape="false"
    :close-on-click-modal="false">
    <AddDeliver :detail="null" :isEdit="false" :orderNo="currentOrder?currentOrder.shop_orderNo:''" @refreshList="refreshList"></AddDeliver>
  </el-dialog>

  <el-dialog title="查看配送记录" :visible.sync="deliverProgressDialogVisible" width="1200px">
    <DeliverList :orderNo="currentOrder?currentOrder.shop_orderNo:''" @refreshList="refreshList"></DeliverList>
  </el-dialog>

  <el-dialog :title="mapDialogTitle" :visible.sync="addressDialogVisible" width="50%" @close="addressDialogClosed">
    <AmapChose ref="mapChoose" :initLocation="initLocation" :onlyShowMap="onlyShowMap" :show="addressDialogVisible"
      @placeChoose="placeChoose" @changeShow="changeShow"></AmapChose>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelChooseAddress">取 消</el-button>
      <el-button type="primary" @click="confirmChooseAddress">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import _ from "lodash";
import cityData from "@/config/citydata.js";
import { shopOrderList, expressList } from "@/config/config.js";
import { shopOrderStatus } from "@/config/index.js";
import AmapChose from "@/components/amap-choose/amap-choose.vue";
import upload from "@/components/upload";
import { myMixins } from "@/mixins/myMixins.js";
import DeliverList from "./DeliverList.vue";
import AddDeliver from "./AddDeliver.vue";
export default {
  components: {
    AmapChose,
    upload,
    DeliverList,
    AddDeliver
  },
  mixins: [myMixins],
  data() {
    return {
      // 订单列表查询参数
      queryInfo: {
        query: "",
        status: -1,
        page: 1,
        limit: 10,
      },
      total: 0,
      // 订单列表
      list: [],
      keyMap: "88ff4caf7acbe262dc2fe98e757cb8be",
      keyWeb: "7094d6e6f20ede19da379b01d7cf4dda",
      initLocation: "116.787141,32.573306",
      color: "#232323",
      currentOrder: null,
      mapShow: true,
      initFileList: [],
      updateProgressForm: {
        number: "",
        image: "",
        deliverName: "",
        mobile: "",
        deliverDate: "",
        signerName: "",
        signerMobile: "",
        remark: ""
      },
      deliverImages: [],
      updateProgressDialogVisible: false,
      isMultiple: true,
      deliverProgressDialogVisible: false,
      deliverProgressList: [],
      initLocation: "",
      addressDialogVisible: false,
      tempAddressInfo: {},
      mapDialogTitle: "选择地址",
      onlyShowMap: false,
    };
  },
  computed: {
    expressList() {
      return expressList;
    },
  },
  created() {
    let that = this;
    this.getList();
  },
  mounted() {

  },
  methods: {
    getStatus(status) {
      return shopOrderStatus[status];
    },
    async getList() {
      let params = _.cloneDeep(this.queryInfo);
      const { data: res } = await this.$http.get("/web_api/supplier/order_list", {
        params,
      });
      if (res.code !== 200) {
        return this.$message.error("获取订单列表失败！");
      }
      this.list = res.data;
      this.total = res.total || this.list.length;
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.getList();
    },
    handleCurrentChange(newSize) {
      this.queryInfo.page = newSize;
      this.getList();
    },
    handleRemove(response) {
      if (response && response.name == "deliver_proof_image") {
        console.log(response);
        this.deliverImages = response.value.map((item) => {
          return item.url;
        });
      }
    },
    // 监听图片上传成功事件
    handleUploadSuccess(response) {
      console.log("--------------");
      console.log(response);
      if (response && response.name == "deliver_proof_image") {
        this.deliverImages = response.value.map((item) => {
          return item.url;
        });
      }
    },
    viewLocation(row) {
      this.initLocation = `${row.lng}, ${row.lat}`;
      this.mapDialogTitle = "查看位置";
      this.onlyShowMap = true;
      this.addressDialogVisible = true;
    },
    //更新配送记录回调刷新
    refreshList(){
       console.log("更新")
       this.currentOrder = null;
       this.updateProgressDialogVisible = false;
       this.deliverProgressDialogVisible = false;
       this.getList();
    },
    //更新配送记录弹窗
    updateDeliverProgress(row) {
      this.currentOrder = row;
      this.updateProgressDialogVisible = true;
    },
    //查看配送记录
    viewDeliverList(row) {
      this.currentOrder = row;
      this.deliverProgressDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
}
</style>
